import { Button, Callout, Classes, Colors, Dialog, Divider, FormGroup, Icon, NonIdealState, Spinner, TextArea } from '@blueprintjs/core';
import { deepEqual, formatDate, formatDateTime, formatPrice, parseDate } from '@patterns/core';
import { FlexRow, Notifier } from '@patterns/ui';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { axios, session } from '../../session';
import SiemensManagerInstance, { SiemensAsset, SiemensOffice, SiemensSeller } from '../../siemens.manager';
import AssetListBuilder from './asset_list_builder';
import { SiemensCalculation, SiemensProposal } from './calculation.form';
import { Stage } from './create_contract.dialog';
import { SiemensCustomer } from './siemens_customer.form';
import Signatories from './signatories';
import Documents from './documents';
import DataStore from '../../data_store';
import { DateInput, DatePicker } from '@blueprintjs/datetime';
import {PriceList, PricelistSelect} from "../pricelist_select";

const DefaultOffice = {
  "id": 132896,
  "name": "JIT Consulting Oy",
  "postalAddress": {
    "id": 873160,
    "address1": "Tiilipojanlenkki 4 A",
    "address2": "",
    "city": "Vantaa",
    "postalCode": "01720",
    "phoneNumber": "020-7738860",
    "email": ""
  }
} as SiemensOffice;

export interface Props extends WithTranslation {
  isOpen: boolean
  onClose: (reload?: boolean) => void
  proposal: SiemensProposal
}

export interface State {
  stage: number;
  customer?: SiemensCustomer;
  allSellers: SiemensSeller[];
  assets: SiemensAsset[];
  contractNumber: string;
  creditApplicationLabel: string;
  extendedLeasingPrice: string;
  leasePerMonth: string;
  residualValue: number;
  proposal: SiemensProposal;
  office: SiemensOffice;
  seller: SiemensSeller;
  offices: SiemensOffice[];
  sellers: SiemensSeller[];
  startDate: Date;
  term: number;
  validTill: Date;
  isLoading: boolean;
  calculationId: string;
  insurance: string;
  invoice: string;
  isProcessingCalculation: boolean;
  isProcessingCreditDecision: boolean;
  proposalId: string;
  residualEur: string;
  startup: string;
  maintenance: string;

  city: string;
  contactName: string;
  contactEmail: string;
  customerIdx: number;
  customers: SiemensCustomer[];
  isSearching: boolean
  name: string;
  postCode: string;
  registrationNumber: string;
  selected?: SiemensCustomer;
  telephone: string;
  creditComment: string;
  priceList: PriceList;
}

export class ProposalDialog extends React.Component<Props, State> {
  state = {
    stage: 1,
    allSellers: [],
    assets: [],
    contractNumber: '',
    creditApplicationLabel: '',
    extendedLeasingPrice: '0',
    leasePerMonth: '0',
    office: DefaultOffice,
    seller: {} as SiemensSeller,
    offices: [],
    proposal: {} as SiemensProposal,
    residualValue: 0,
    sellers: [],
    startDate: new Date(),
    term: 24,
    validTill: new Date(),
    isLoading: false,
    calculationId: '',
    insurance: '0.00',
    invoice: '0.00',
    isProcessingCalculation: false,
    isProcessingCreditDecision: false,
    proposalId: '',
    residualEur: '',
    startup: '0.00',
    maintenance: '0.00',
    city: '',
    contactEmail: '',
    contactName: '',
    customerIdx: -1,
    customers: [] as SiemensCustomer[],
    isSearching: false,
    name: '',
    postCode: '',
    registrationNumber: '',
    telephone: '',
    creditComment: `Liitetään ${session.lesseeName}:n puitevuokrasopimukseen. Ei avausmaksua eikä laskutuslisää. Vuokrasopimus alkaa ${formatDate(new Date())}. JIT Systems laskuttaa asiakkaalta päivävuokrat.`,
    priceList: {
      id: 14736,
      name: 'Puitevuokra 2020'
    } as PriceList,
  } as State

  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.isOpen === false && this.props.isOpen === true) {
      this.setState({
        contractNumber: '',
        customer: this.state.proposal.customer,
        creditApplicationLabel: '',
        extendedLeasingPrice: '0',
        leasePerMonth: '0',
        seller: {} as SiemensSeller,
        office: DefaultOffice as SiemensOffice,
        stage: 1,
        startDate: new Date(),
        validTill: new Date(),
        isLoading: true
      }, () => {
        this.fetch()
      })
    }

    // if (!deepEqual(this.state.assets, prevState.assets)) {
    //   this.handleAssetChanges(prevState)
    // }
  }

  private createContract = () => {
    this.setState({ isLoading: true }, async () => {
      try {
        await axios.post(`siemens/proposals/${this.state.proposal.id}/convert`);
        this.setState({ isLoading: false }, () => {
          Notifier.success('Contract was successfully imported');
          this.props.onClose(true);
        });
      } catch(e) {
        this.setState({ isLoading: false }, () => {
          Notifier.failure('An error occured during proposal import')
        })
      }
    })
  }

  private close = () => {
    this.props.onClose()
  }

  private fetch = async () => {
    this.setState({ isLoading: true }, async () => {
      let newState = {} as State;

      if (this.state.sellers.length === 0) {
        try {
          const officesRes = await axios.get('/siemens/offices');
          const sellersRes = await axios.get('/siemens/sellers');
          const sellers = (sellersRes.data.sellers as SiemensSeller[]).filter(seller => seller.officeIds.includes(this.state.office.id));
          newState = {
            sellers,
            offices: officesRes.data.offices as SiemensOffice[],
            allSellers: sellersRes.data.sellers as SiemensSeller[]
          } as State

          this.setState(newState)
        } catch (e) {
          console.error('proposals siemens fetch failed');
        }
      }

      if (this.props.proposal && this.props.proposal.id && this.props.proposal.id > 0) {
        try {
          const proposalRes = await axios.get(`/siemens/proposals/${this.props.proposal.id}`);
          const customer = proposalRes.data.proposal.customer as any;
          newState.proposal = proposalRes.data.proposal as SiemensProposal;
          newState.city = customer.businessPartner?.officialAddress.city || '';
          newState.contactEmail = customer.invoiceContact?.email || '';
          newState.contactName = customer.invoiceContact?.name || '';
          newState.name = customer.businessPartner?.name || '';
          newState.postCode = customer.businessPartner?.officialAddress.postalCode || '';
          newState.registrationNumber = this.getRegistrationNumber(customer) || '';
          newState.telephone = customer.businessPartner.officialAddress.phoneNumber || '';
          newState.calculationId = `${proposalRes.data.proposal.currentCalculation?.id || ''}`;
          this.setState(newState, () => this.fetchAssets())
        } catch (e) {
          console.error('proposals siemens fetch of proposals failed');
        }
      }

    })
  }

  private fetchAssets = async () => {
    if (this.props.proposal.id) {
      const assetsRes = await axios.get(`/siemens/proposals/${this.props.proposal.id}/assets`);
      const assets = assetsRes.data.assets.data;
      let lifecycle = 36;
      let residualValue = this.state.residualValue;

      if (assets.length > 0) {
        const asset = assets[0];
        const category = DataStore.categoryBySubtype(asset.assetSubType.id);
        if (category) {
          lifecycle = category.lifecycle;
          residualValue = category.residualValue;
          console.log('category residual value applied', category);
        } else {
          console.log('category residual value skipped');
        }
      } else {
        console.log('asset residual value scan failed')
      }

      console.log('setting lifecycle to', lifecycle, 'residual', residualValue);
      this.setState({
        assets,
        isLoading: false ,
        term: lifecycle,
        residualValue
      }, () => this.setCalculation())
    } else {
      this.setState({ isLoading: false })
    }
  }

  private setCalculation() {
    const calculation = this.state.proposal.currentCalculation;
    if (calculation) {
      console.log('setting residual value from calculation', calculation.residualValue.percentage);
      this.setState({
        calculationId: calculation.id!.toString(),
        insurance: calculation.fees!.find((f: any) => f.feeType.name === 'Insurance')?.amount.toFixed(2) ?? '0.00',
        invoice: calculation.fees!.find((f: any) => f.feeType.name === 'Invoice')?.amount.toFixed(2) ?? '0.00',
        maintenance: calculation.fees!.find((f: any) => f.feeType.name === 'Maintenance')?.amount.toFixed(2) ?? '0.00',
        startup: calculation.fees!.find((f: any) => f.feeType.name === 'Startup')?.amount.toFixed(2) ?? '0.00',
        term: parseInt(calculation.term!),
        residualValue: calculation.residualValue.percentage.toFixed(2),
        residualEur: calculation.residualValue.amount.toFixed(2),
        isProcessingCalculation: false
      });

      // if (this.state.proposal.decision && this.state.proposal.decision.decisionStatus === 'ACCEPTED' && this.state.proposal.signatories.length === 0) {
      //   this.addDefaultSignatories()
      // }
    }
  }

  private mapState = (prevProps: Props) => {
    if (this.props.proposal && !deepEqual(prevProps.proposal, this.props.proposal) && this.props.proposal.customer) {
      const customer = this.props.proposal.customer as any;
      this.setState({
        city: customer.businessPartner?.officialAddress.city || '',
        contactEmail: customer.invoiceContact?.email || '',
        contactName: customer.invoiceContact?.name || '',
        name: customer.businessPartner.name || '',
        postCode: customer.businessPartner.officialAddress.postalCode || '',
        registrationNumber: this.getRegistrationNumber(customer) || '',
        telephone: customer.businessPartner.officialAddress.phoneNumber || '',
      })
    }
  }

  getRegistrationNumber = (customer: SiemensCustomer) => {
    return customer.businessPartnerRef ? customer.businessPartnerRef.registrationNumber : (customer.businessPartner?.registrationNumber || '')
  }


  private request = () => {
    if (this.state.calculationId.length > 0) {
      this.requestCreditDecision();
    } else {
      this.requestCalculation();
    }
  }

  private requestCreditDecision = () => {
    this.setState({ isProcessingCreditDecision: true }, async () => {
      try {
        await SiemensManagerInstance.requestCreditDecision(this.props.proposal.id.toString(), this.state.creditComment);
        this.setState({
          isProcessingCreditDecision: false,
        }, () => {
          this.fetch()
        })
      } catch (e) {
        console.error('catched error', e);
        this.setState({
          isProcessingCreditDecision: false
        }, () => {
          alert('Calculation Request timed out')
        })
      }
    })
  }

  private requestCalculation = () => {
    this.setState({ isProcessingCalculation: true }, async () => {
      let lifecycle = 36;
      let residualValue = this.state.residualValue;

      if (this.state.assets.length > 0) {
        const asset = this.state.assets[0];
        const category = DataStore.categoryBySubtype(asset.assetSubTypeId);
        if (category) {
          lifecycle = category.lifecycle;
          residualValue = category.residualValue;
        }
        if (lifecycle !== this.state.term) {
          console.log('setting residualValue from requestCalculation', residualValue);
          this.setState({
            term: lifecycle ,
            residualValue
          })
        }
      }

      const calculation: SiemensCalculation = {
        operation: 'CALC_RENTAL',
        sheet: {
          marketProductId: 70,
          introducerPricelistId: this.state.priceList.id,
          residualValue: {
            "amount": 0,
            "percentage": this.state.residualValue,
            "usePercentage": true,
            "manualSet": true
          }
        },
        term: this.state.term.toFixed(0),
        fees: []
      }

      console.log('sending calculation', calculation);

      try {
        const response = await axios.post(`/siemens/proposals/${this.props.proposal!.id}/calculations`, calculation);
        this.setState({
          calculationId: response.data.data.currentCalculation?.id ?? this.state.calculationId,
          isProcessingCalculation: false,
          proposalId: response.data.data.proposalNumber
        }, () => {
          this.fetch()
        })
      } catch (e) {
        console.error('catched error', e);
        this.setState({
          isProcessingCalculation: false
        }, () => {
          alert('Calculation Request timed out')
        })
      }
    })
  }

  private setStage = (stage: number) => this.setState({ stage })

  private onCustomerChange = (customer: SiemensCustomer) => { this.setState({ customer }) }

  private onAssetDelete = async (asset_id: number) => {
    const asset  = this.state.assets.find(a => a.id === asset_id);
    if (!asset) {
      return
    }

    // console.log('on asset delete', asset_id, asset);
    // const invoice = await PurchaseInvoiceRepository.get(asset.invoiceId!);

    // console.log('invoice', invoice);
    // const invoiceAsset = invoice.assets[asset.id!];
    // invoiceAsset.proposalId = 0;
    // await PurchaseInvoiceRepository.save(invoice);

    // console.log('should handle asset changes -> removed', invoiceAsset);
    console.log('removing from siemens');
    this.setState({ isLoading: true }, async () => {
      await axios.delete(`/siemens/proposals/${this.props.proposal.id}/assets/${asset_id}`);
      await this.fetchAssets();
      this.setState({ isLoading: false })
    })
  }

  private onUpdateRequest = () => {
    this.fetchAssets()
  }

  private renderStep = (stage: Stage, label: string) => <div className="f-4 d-f f-c ai-c jc-c" onClick={() => this.setStage(stage)}>
    <div className={this.state.stage === stage ? 'wizard-step active' : 'wizard-step'}>{ stage }</div>
    <div className={this.state.stage === stage ? 'wizard-label active' : 'wizard-label'}>{ label }</div>
  </div>

  private renderStepSpacer = () => <div className="f-1 d-f f-c ai-c jc-c h-50">
    <Icon icon="chevron-right" iconSize={36} color={Colors.DARK_GRAY5}/>
  </div>

  private onPriceListSelect = (priceList: PriceList | undefined) => this.setState({ priceList: priceList ?? { id: 0, name: '' } })

  private renderLoader = () => {
    return <NonIdealState>
      <Spinner size={96} className="m-t-24" intent="success"/>
    </NonIdealState>
  }

  private renderContent = () => {
    const { t } = this.props;
    const assetValue = this.state.assets.reduce((total, asset) => total += parseFloat(asset.totalValue), 0);
    const proposal = this.state.proposal;

    if (!this.state.proposal || !this.state.proposal.id) {
      return <NonIdealState title="Loading"/>
    }

    console.log('startDate', this.state.startDate);
    const customer = proposal.customer;
    const invoiceAddress = customer.invoiceAddress ?? customer.businessPartner?.officialAddress;
    console.log('customer', customer);

    return <React.Fragment>
      <div className="proposal-credit-decision f-1">
        <h2>{t('lessee')}</h2>
        <div className="dark-box">
          <FlexRow>
            <div className="f-2">
              <FlexRow className="m-b-12">
                <div className="f-1">
                  { t('proposals.customer.name') }
                </div>

                <div className="f-2 bold">
                  { customer.businessPartner!.name }
                </div>
              </FlexRow>
            </div>

            <div className="f-2 m-l-24">
              <FlexRow className="m-b-12">
                <div className="f-1">
                  { t('official_address') }
                </div>

                <div className="f-2 bold">
                  { [customer.businessPartner?.officialAddress.address1, customer.businessPartner?.officialAddress.address2, customer.businessPartner?.officialAddress.postalCode, customer.businessPartner?.officialAddress.city].filter(f => f).join(', ') }
                </div>
              </FlexRow>
            </div>

            <div className="f-2 m-l-24">
              <FlexRow className="m-b-12">
                <div className="f-1">
                  { t('proposals.customer.contact_name') }
                </div>

                <div className="f-2 bold">
                  { customer.invoiceContact.name }
                </div>
              </FlexRow>
            </div>

          </FlexRow>

          <FlexRow>
            <div className="f-2">
              <FlexRow className="m-b-12">
                <div className="f-1">
                  { t('proposals.customer.registration_number') }
                </div>

                <div className="f-2 bold">
                  { customer.businessPartner?.registrationNumber }
                </div>
              </FlexRow>
            </div>

            <div className="f-2 m-l-24">
              <FlexRow className="m-b-12">
                <div className="f-1">
                  { t('proposals.customer.invoice_address') }
                </div>

                <div className="f-2 bold">
                  { [invoiceAddress.address1, invoiceAddress.address2, invoiceAddress.postalCode, invoiceAddress.city].filter(f => f).join(', ') }
                </div>
              </FlexRow>
            </div>

            <div className="f-2 m-l-24">
              <FlexRow className="m-b-12">
                <div className="f-1">
                  { t('proposals.customer.contact_email') }
                </div>

                <div className="f-2 bold">
                  { customer.invoiceContact.email }
                </div>
              </FlexRow>
            </div>

          </FlexRow>

        </div>
      </div>

      <AssetListBuilder
        assets={this.state.assets}
        proposal={this.state.proposal}
        onChange={(assets, residualValue) => this.setState({ assets, residualValue })}
        showPurchasePool={true}
        onAssetDelete={this.onAssetDelete}
        onUpdateRequest={this.onUpdateRequest}
      />

      { (this.state.stage === 2 && this.state.isLoading) && <NonIdealState className="loader-overlay">
          <Spinner size={96} className="m-t-24" intent="success"/>
        </NonIdealState>
      }

      <FlexRow>
        <div className="proposal-credit-decision f-1 m-t-12 m-r-24">
          <div className="">
            <h2>{t('proposals.current_calculation')}</h2>

            { this.state.calculationId.length > 0 && !this.state.isProcessingCalculation && <div className="dark-box">

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('product')}
                </div>
                <div className="f-1 bold">
                  Vuokrasopinus
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('proposals.price_list')}
                </div>
                <div className="f-1 bold">
                  { this.state.proposal?.currentCalculation?.introducerPricelist?.name ?? '' }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('proposals.asset_value')}
                </div>
                <div className="f-1 bold">
                  { formatPrice(assetValue) }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('proposals.insurance')}
                </div>
                <div className="f-1 bold">
                  { formatPrice(this.state.insurance) }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('proposals.term')}
                </div>
                <div className="f-1 bold">
                  { this.state.term }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('proposals.startup')}
                </div>
                <div className="f-1 bold">
                  { formatPrice(this.state.startup) }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('proposals.rental')}
                </div>
                <div className="f-1 bold">
                    { formatPrice(this.state.proposal?.currentCalculation?.payment?.paymentPerMonth ?? 0) }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('proposals.maintenance')}
                </div>
                <div className="f-1 bold">
                  { formatPrice(this.state.maintenance) }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('residual_value')}
                </div>
                <div className="f-1 bold">
                  { formatPrice(this.state.residualEur) }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('invoice')}
                </div>
                <div className="f-1 bold">
                  { formatPrice(this.state.invoice) }
                </div>
              </FlexRow>
            </div> }

            { this.state.assets.length > 0 && this.state.calculationId.length === 0 && !this.state.isProcessingCalculation && <div>
              {/* <span className="m-r-12">{t('select_term')}</span>
              <TermSelect
                term={this.state.term}
                onSelect={term => this.setState({ term })}
              />
              <br/> */}
              <FlexRow className="labels-left">
                <PricelistSelect
                    inline={true}
                    label={t('proposals.price_list')}
                    priceList={this.state.priceList}
                    onChange={this.onPriceListSelect}
                />
              </FlexRow>
              <Button
                text={t('request_calculation')}
                intent="success"
                icon="cloud-upload"
                onClick={this.request}
              />
            </div> }

            { this.state.assets.length === 0 && <Callout>
              {t('add_assets_first')}
            </Callout> }

            { this.state.isProcessingCalculation &&  <Spinner size={36} className="m-l-24"/> }
          </div>
        </div>

        <div className="proposal-credit-decision f-1 m-t-12">
          <div>
            <h2>{t('proposals.credit_decision_overview')}</h2>
            { this.state.proposal.decision && !this.state.isProcessingCreditDecision && <div className="dark-box">
              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('status')}
                </div>
                <div className="f-1 bold">
                  { this.state.proposal.decision ? this.state.proposal.decision.decisionStatus : '' }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('proposals.requested_at')}
                </div>
                <div className="f-1 bold">
                  { this.state.proposal.decision?.decisionRequestedDate ? formatDateTime(this.state.proposal.decision?.decisionRequestedDate) : '' }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('proposals.decision_date')}
                </div>
                <div className="f-1 bold">
                  { this.state.proposal.decision?.decisionDate ? formatDateTime(this.state.proposal.decision?.decisionDate) : '' }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('proposals.comment')}
                </div>
                <div className="f-1 bold">
                  { this.state.proposal.decision?.comment ? this.state.proposal.decision?.comment : '' }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('proposals.comment_to_risk_analysis')}
                </div>
                <div className="f-1 bold">
                  { this.state.proposal.decision?.commentToRiskAnalyst ? this.state.proposal.decision?.commentToRiskAnalyst : '' }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('proposals.condition')}
                </div>
                <div className="f-1 bold">
                  { this.state.proposal.decision?.condition ? this.state.proposal.decision?.condition : '' }
                </div>
              </FlexRow>

              <FlexRow className="m-b-12">
                <div className="f-1">
                  {t('proposals.reason')}
                </div>
                <div className="f-1 bold">
                  { this.state.proposal.decision?.reason ? this.state.proposal.decision?.reason : '' }
                </div>
              </FlexRow>
            </div> }

            { this.state.calculationId.length > 0 && !this.state.proposal.decision && !this.state.isProcessingCreditDecision && <div>
              <FormGroup label="Start Date">
                <DateInput
                  formatDate={formatDate}
                  parseDate={parseDate}
                  value={this.state.startDate}
                  onChange={(date, isUserChange) => {
                    if (isUserChange) {
                      this.setState({
                        startDate: date || new Date(),
                        creditComment: `Liitetään ${session.lesseeName}:n puitevuokrasopimukseen. Ei avausmaksua eikä laskutuslisää. Vuokrasopimus alkaa ${formatDate(date || new Date())}. JIT Systems laskuttaa asiakkaalta päivävuokrat.`
                      })}
                    }
                  }
                />

                {/* <TextArea
                  value={this.state.creditComment}
                  onChange={evt => this.setState({ creditComment: evt.target.value})}
                  style={{ width: '100%' }}
                /> */}
              </FormGroup>
              <FormGroup label="Comment">
                <TextArea
                  value={this.state.creditComment}
                  onChange={evt => this.setState({ creditComment: evt.target.value})}
                  style={{ width: '100%' }}
                />
              </FormGroup>
              <Button
                text={t('request_credit_decision')}
                intent="success"
                icon="cloud-upload"
                onClick={this.request}
                className="m-b-24"
              />
            </div> }

            { this.state.calculationId.length === 0 && <Callout intent="none" className="m-t-12">
              {t('waiting_for_calculation')}
            </Callout> }

            { this.state.isProcessingCreditDecision &&  <Spinner size={36} className="m-l-24"/> }
          </div>
        </div>

        <div className="proposal-credit-decision f-1 m-t-12 m-l-24">
          <Documents
            proposal={this.state.proposal}
            onChange={this.fetch}
          />

          { this.state.proposal.decision && <Divider className="m-t-24 m-b-24"/> }

          { this.state.proposal.decision && <Signatories
            proposal={this.state.proposal}
            onChange={this.fetch}
          /> }
        </div>
      </FlexRow>


    </React.Fragment>
  }
  public render() {
    const { t } = this.props;

    return (
      <Dialog canOutsideClickClose={false} isOpen={this.props.isOpen} onClose={() => this.props.onClose(false)} title={`${t('proposal')} ${this.props.proposal.proposalNumber}`} style={{ width: 1400 }} className="bp3-dark">
        <div className={Classes.DIALOG_BODY}>
          { this.state.isLoading ? this.renderLoader() : this.renderContent() }
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>

            { (this.state.proposal.status?.status === 'DOCUMENTS_ARRIVED' || this.state.proposal.status?.status === 'CONTRACT_STARTED') && <Button
              text={t('proposals.create_contract')}
              rightIcon="tick"
              onClick={this.createContract}
              intent="success"
            /> }

            { !this.state.isLoading && <Button
              text={t('close')}
              icon="cross"
              onClick={() => this.props.onClose(false)}
            /> }
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation()(ProposalDialog)
